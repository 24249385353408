import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import trainingLinks from '../../assets/trainingLinks.json';
import { SERVER_URL } from 'src/environments/environment';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-training-modal',
  templateUrl: './training-modal.page.html',
  styleUrls: ['./training-modal.page.scss'],
})
export class TrainingModalPage implements OnInit {
  @Input() id;
  @Input() receiver: boolean;
  @Input() name: string;

  assignedTrainings: any;
  selectedTraining: any;
  title: string;
  url: string;

  constructor(
    public modalController: ModalController,
    public authService: AuthService,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.selectedTraining = [];
    this.assignedTrainings = [];
    const requestOptions = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      }
    };
    fetch(window.location.protocol + '//' + window.location.hostname + "/api/getTraining/" + this.id, requestOptions)
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
          this.assignedTrainings = data;
          console.log(this.assignedTrainings);
        });
      } else {
        throw new Error("Error");
      }
    })
    .catch(error => {
      console.error("Error: ", error);
    });
}

  dismiss() {
    this.modalController.dismiss();
  }

  onTrainingChecked(training) {
    this.selectedTraining.push(training);
    console.log(this.selectedTraining);
  }

  submitTrainingCompleted() {
    this.selectedTraining.forEach(training => {
      const requestOptions = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'training_id': training.id,
          'employee_id': this.id,
        })
      };
  
      fetch(window.location.protocol + '//' + window.location.hostname + "/api/completeTraining", requestOptions)
        .then(response => {
          if (response.ok) {
            return response;
          } else {
            throw new Error("Error");
          }
        })
        .catch(error => {
          console.error("Error: ", error);
        });
    });
    this.modalController.dismiss();
  }
}