import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { 
  AuthGuardService as AuthGuard 
} from './services/auth-guard.service'
const routes: Routes = [
 
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
 
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home-details/home-details.module').then( m => m.HomeDetailsPageModule)
  },
  {
    path: 'pulse',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pulse/pulse.module').then( m => m.PulsePageModule)
  },
  {
    path: 'feedback',
    canActivate: [AuthGuard],
    loadChildren: () => import('./feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: 'team-profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./team-profile/team-profile.module').then( m => m.TeamProfilePageModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'pulse-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pulse-details/pulse-details.module').then( m => m.PulseDetailsPageModule)
  },
  {
    path: 'pulse-success',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pulse-success/pulse-success.module').then( m => m.PulseSuccessPageModule)
  },
  {
    path: 'pulse-modal',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pulse-modal/pulse-modal.module').then( m => m.PulseModalPageModule)
  },
  {
    path: 'feedback-board',
    canActivate: [AuthGuard],
    loadChildren: () => import('./feedback-board/feedback-board.module').then( m => m.FeedbackBoardPageModule)
  },
  {
    path: 'notification',
    canActivate: [AuthGuard],
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'profile-detail',
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile-detail/profile-detail.module').then( m => m.ProfileDetailPageModule)
  },
  {
    path: 'profile-feedback',
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile-feedback/profile-feedback.module').then( m => m.ProfileFeedbackPageModule)
  },
  {
    path: 'feedback-modal',
    canActivate: [AuthGuard],
    loadChildren: () => import('./feedback-modal/feedback-modal.module').then( m => m.FeedbackModalPageModule)
  },
  {
    path: 'home-action',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home-action/home-action.module').then( m => m.HomeActionPageModule)
  },
  {
    path: 'action-modal',
    canActivate: [AuthGuard],
    loadChildren: () => import('./action-modal/action-modal.module').then( m => m.ActionModalPageModule)
  },
  {
    path: 'home-calendar',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home-calendar/home-calendar.module').then( m => m.HomeCalendarPageModule)
  },
  {
    path: 'home-customer',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home-customer/home-customer.module').then( m => m.HomeCustomerPageModule)
  },
  {
    path: 'home-customer-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home-customer-details/home-customer-details.module').then( m => m.HomeCustomerDetailsPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'profile-mission',
    loadChildren: () => import('./profile-mission/profile-mission.module').then( m => m.ProfileMissionPageModule)
  },
  {
    path: 'training-detail-modal',
    loadChildren: () => import('./training-detail-modal/training-detail-modal.module').then( m => m.TrainingDetailModalPageModule)
  },
  {
    path: 'training-modal',
    loadChildren: () => import('./training-modal/training-modal.module').then( m => m.TrainingModalPageModule)
  },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
