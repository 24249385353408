import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-pulse-modal',
  templateUrl: './pulse-modal.page.html',
  styleUrls: ['./pulse-modal.page.scss'],
})
export class PulseModalPage implements OnInit {

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {
  }
  dismiss() {
    this.modalController.dismiss();
  }

}
