import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { switchMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {v4 as uuidv4} from 'uuid';
import { SERVER_URL } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
 
export interface comments {
  createdAt: Date;
  id: number;
  content: string;
  postId: number;
}
 
@Injectable({
  providedIn: 'root'
})
export class CommentService {
 
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
};
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private http: HttpClient,) {
   
  }
 
  comments: any;
 
 
  // TODO Chat functionality
  addcomments(content, type, userId, postId,profile,userName){
    this.afs.collection('notifications').add({
      userId: userId,
      type: type,
      postId: postId,
      profile: profile,
      content: `${userName} has commented on your posting `,
      createdAt: new Date(),
    })
    return this.afs.collection('comments').add({
      ID:  uuidv4(),
      content: content,
      userId: userId,
      type: type,
      postId: postId,
      userName: userName,
      ImageURL: profile,
      createdAt: new Date(),
    });
  }
   
  getcomments(postid) {
    let users = [];
    return this.getAllcomments().pipe(
      switchMap(res => {
        this.comments = res;
        return this.afs.collection('comments', ref => ref.orderBy('createdAt')).valueChanges() as Observable<comments[]>;
      }),
      map(comments => {
        
        comments = comments.filter(x =>x.postId == postid);      
        return comments
      })
    )
  }
   
  private getAllcomments() {
    return this.afs.collection('comments').valueChanges() as Observable<comments[]>;
  }
   
  deleteComment(commentId){
    console.log("🚀 ~ commentId", commentId)
    console.log("🚀 ~ Method API not implemented")
    const url = SERVER_URL + '/api/comment/delete';
    let httpParams = new HttpParams()
      .set("ID", commentId)
  
    return this.http.post(url, httpParams , this.httpOptions);

  }
 
  
}