// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCGUxvBkzpTP4zprC6Ehd8jOXCvBz-byhU",
    authDomain: "yingfung-d9fac.firebaseapp.com",
    databaseURL: "https://yingfung-d9fac.firebaseio.com",
    projectId: "drivec-ba479",
    storageBucket: "yingfung-d9fac.appspot.com",
    messagingSenderId: "463647462563"
  },
  chatgptKey: "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",
  // database: {
  //   name: "technicians/"
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
export const SERVER_URL = 'https://dev.drivebusinessintelligence.com';