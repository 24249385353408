import * as moment from 'moment';

export function timeSince(date: Date | string | number): string {
  const now = moment();
  const past = moment(date);
  const duration = moment.duration(now.diff(past));

  if (duration.years() > 0) {
    return `${duration.years()} years`;
  } else if (duration.months() > 0) {
    return `${duration.months()} months`;
  } else if (duration.days() > 0) {
    return `${duration.days()} days`;
  } else if (duration.hours() > 0) {
    return `${duration.hours()} hours`;
  } else if (duration.minutes() > 0) {
    return `${duration.minutes()} minutes`;
  } else {
    return `${duration.seconds()} seconds`;
  }
}

  export function getPeriodData(survey,period){
    let currentPeriod = [];
    let periviousPeriod = [];
    let today = new Date();
    switch(period){
      case 1:
        
        currentPeriod = survey.filter(x => 
          new Date(x.DateOfVisit) > new Date(new Date().setDate(today.getDate() -1))
        );
        periviousPeriod = survey.filter(x => 
          new Date(new Date().setDate(new Date(x.DateOfVisit).getDate() -1)) > new Date(new Date().setDate(today.getDate() -2))
        );
        break;

      case 2:

         currentPeriod = survey.filter(x => 
          
          new Date(x.DateOfVisit) > new Date(new Date().setDate(today.getDate() -7))
        );
        periviousPeriod = survey.filter(x => 
          new Date(new Date().setDate(new Date(x.DateOfVisit).getDate() -7)) > new Date(new Date().setDate(today.getDate() -14))
        );
        break;

      case 3:
        console.log('asdc3')

         currentPeriod = survey.filter(x => 
          new Date(x.DateOfVisit) > new Date(new Date().setDate(today.getDate() - 30))
        );
        periviousPeriod = survey.filter(x => 
          new Date(new Date().setDate(new Date(x.DateOfVisit).getDate() -30)) > new Date(new Date().setDate(today.getDate() -60))
        );
        break;

      }
      console.log(currentPeriod);
      return {
        currentPeriod : currentPeriod,
        periviousPeriod : periviousPeriod,
      }
  }
  export function getPeriodStartAndEnd(period){
    let startDate;
    let endDate = moment().endOf('day');
    switch(period){
      case 1:
        startDate = moment().startOf('day')
        break;

      case 2:
        startDate = moment().subtract(6, 'day').startOf('day')
        break;

      case 3:
        startDate = moment().subtract(1, 'month').startOf('day')
        break;

      }
      return {
        startDate,
        endDate,
      }
  }