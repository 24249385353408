import { Injectable } from '@angular/core';
import { switchMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {v4 as uuidv4} from 'uuid';
import { SERVER_URL } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
 
export interface comments {
  id: number;
  userId: number;
  postId: number;
  username: string;
  createdAt: Date;
  content: string;
  imageUrl: string;
}
 
@Injectable({
  providedIn: 'root'
})
export class CommentService {
 
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
};
  constructor(private http: HttpClient,) {}
 
  comments: any;
  private apiUrl = window.location.protocol + '//' + window.location.hostname  + '/api';
 
 
  getCommentsByPostId(id: number): Observable<comments> {
      return this.http.get<comments>(`${this.apiUrl}/posts/${id}`);
  }

  createComment(comment: comments): Observable<comments> {
    return this.http.post<comments>(`${this.apiUrl}/posts`, comment);
  }

  deleteComment(id: number): Observable<void> {
      return this.http.delete<void>(`${this.apiUrl}/posts/${id}`);
  }
  
}